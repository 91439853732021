<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
  >
    <template v-slot:top>
      <rs-table-title-bar
        v-if="$refs.headSearch"
        :title="title"
        icon="mdi mdi-truck"
        :addRoute="!showAdd ? null : { name: 'my-company.providers.create' }"
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        @click:edit="handleEditClick"
        :edit-enabled="selectedItems.length === 1"
        :search.sync="search.query"
        :hide-edit="!can('edit-company')"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals
        :headers="headers"
        :totals="footTotals"
        v-if="list.length > 0"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        class="btn btn-icon btn-sm btn-clean"
        :to="{
          name: 'my-company.providers.edit',
          params: { id: item.id },
        }"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.cluster_name="{ item }">
      <router-link
        v-if="can('see-cluster')"
        :to="{
          name: 'definitions.clusters.show',
          params: { id: item.cluster_id },
        }"
      >
        {{ item.cluster_name }}
      </router-link>
      <template v-else>{{ item.cluster_name }}</template>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.name="{ item }">
      <router-link
        v-if="item.cluster_id && can('see-cluster-provider')"
        :to="{
          name: 'definitions.cluster-providers.show',
          params: { id: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
      <template v-else>{{ item.name }}</template>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.provider_type_id="{ item }">
      {{ $t(`provider.type.${item.provider_type_id}`) }}
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.owner_email="{ item }">
      <a :href="`mailto:${item.owner_email}`" v-if="item.owner_email">
        {{ item.owner_email }}
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.owner_phone="{ item }">
      <a :href="`tel:${item.owner_phone}`" v-if="item.owner_phone">
        {{ item.owner_phone }}
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.debt="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.due="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.diff="{ value }">
      <rs-table-cell-balance colored :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasPermissions],
  props: {
    showSearch: {
      type: Boolean,
      default: false,
      required: false,
    },
    companyId: {
      type: Number,
      default: null,
      required: false,
    },
    parentId: {
      type: Number,
      default: null,
      required: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: "Şirket Tedarikçileri",
      required: false,
    },
    showBalance: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["fullExpenseTypeList", "providerTypeList"]),
  },
  data() {
    return {
      headers: [],
    };
  },
  watch: {
    options: {
      handler() {
        // this.getDataFromApi()
        this.loadList(this.options);
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    companyId(n, o) {
      if (n !== o) {
        this.loadList();
      }
    },
    parentId(n, o) {
      if (n !== o) {
        this.loadList();
      }
    },
  },
  created() {
    if (this.showEdit) {
      this.headers.push({
        text: "",
        value: "edit",
        searchable: false,
        sortable: false,
        width: "30px",
        align: "center",
      });
    }

    if (this.parentId) {
      this.headers.push({
        text: this.$t("labels.cluster_name"),
        value: "cluster_name",
        searchable: "text",
      });
    }

    this.headers = this.headers.concat([
      {
        text: this.$t("labels.provider_name"),
        value: "name",
        searchable: "text",
      },
      {
        text: this.$t("labels.provider_type"),
        value: "provider_type_id",
        searchable: "select",
        options: this.providerTypeList,
      },
      {
        text: this.$t("labels.debt"),
        value: "debt",
        searchable: "number",
        align: "end",
        hide: () => !this.showBalance,
      },
      {
        text: this.$t("labels.due"),
        value: "due",
        searchable: "number",
        align: "end",
        hide: () => !this.showBalance,
      },
      {
        text: this.$t("labels.balance"),
        value: "diff",
        searchable: "number",
        align: "end",
        hide: () => !this.showBalance,
      },
      {
        text: "Yetkili",
        value: "owner_name",
        searchable: "text",
      },
      {
        text: "Yetkili Telefon",
        value: "owner_phone",
        searchable: "text",
      },
      {
        text: "Yetkili E-Posta",
        value: "owner_email",
        searchable: "text",
      },
      {
        text: "Gelir/Gider Türü",
        value: "expense_type",
        searchable: "select",
        options: () => this.fullExpenseTypeList,
      },
      {
        text: this.$t("labels.status"),
        value: "is_active",
        searchable: "isActive",
        align: "center",
      },
    ]);

    this.loadList();
  },
  methods: {
    /**
     * Retrieves the list of company from api
     * @return {Promise} Resolves if list is retrieved
     */
    loadList() {
      if (this.isLoading || (!this.companyId && !this.parentId)) {
        return false;
      }

      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.companyId) {
        params.provides_id = this.companyId;
      } else {
        params.parent_id = this.parentId;
      }

      this.isLoading = true;

      this.$api
        .query("company-providers", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            this.$emit("update:item-count", this.total);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "my-company.providers.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
};
</script>
