<template>
  <rs-side-panel ref="sidePanel">
    <template slot="body">
      <v-data-table
        class="mt-5"
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Geçmiş"
            icon="mdi mdi-home"
            @clear-filters="clearFilters()"
            @click:edit="handleEditClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_on="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>
      </v-data-table>

      <rs-form-buttons
        @cancel="handleCancelClick"
        :is-loading="isLoading"
        hide-submit
        hide-save-close
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "ActionLogList",
  mixins: [hasDataTable],
  computed: {
    ...mapGetters(["actionTypeList", "clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  data() {
    return {
      loggableType: null,
      loggableId: null,
      options: {
        sortBy: ["action_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Eylem",
          value: "action_type",
          searchable: "multiselect",
          options: () => this.actionTypeList,
        },
        {
          text: "Kullanıcı",
          value: "user",
          searchable: "text",
        },
        {
          text: "Tarih-Saat",
          value: "action_on",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    show(loggableType, loggableId) {
      if (!loggableType || !loggableId || this.isLoading) {
        return false;
      }

      this.loggableType = loggableType;
      this.loggableId = loggableId;

      this.loadList();

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.action_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options),
        { loggable_type: this.loggableType, loggable_id: this.loggableId }
      );

      this.$api
        .query(`clusters/${this.clusterId}/action-logs`, { params })
        .then((response) => {
          this.list = response.data.data;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
