<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tedarikçi - TYA Eşleştir</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-data-table
          :fixed-header="false"
          :height="undefined"
          v-bind="dataTableAttrs"
          :headers="headers"
          :items="list"
          :loading="isLoading"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page.sync="itemsPerPage"
          v-model="selectedItems"
          @update:items-per-page="handleItemsPerPageUpdated"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:body.prepend="{ headers }">
            <rs-table-head-search
              :headers="headers"
              :search.sync="search"
              ref="headSearch"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.bank_integration="{ item }">
            <v-icon v-if="item.bank_integration" color="success">
              mdi-check
            </v-icon>
            <v-icon v-else color="warning">mdi-close</v-icon>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.is_active="{ item }">
            <v-icon v-if="item.is_active" color="success">mdi-check</v-icon>
            <v-icon v-else color="warning">mdi-close</v-icon>
          </template>
        </v-data-table>

        <rs-form-buttons
          :isLoading="isLoading"
          @cancel="hide()"
          hide-save-close
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasForm } from "@/view/mixins";

export default {
  props: {
    company: {
      type: Object,
      required: false,
      default: () => {},
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      isLoading: false,
      providerId: null,
      formData: {
        cluster_ids: [],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "İsim",
          value: "name",
          searchable: "text",
          sortable: true,
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          sortable: true,
          align: "center",
        },
        {
          text: "Banka Entegrasyonu",
          value: "bank_integration",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.authorized_person"),
          value: "authorized_person",
          searchable: "text",
          sortable: true,
        },
        {
          text: this.$t("labels.house_count"),
          value: "house_count",
          searchable: false,
          sortable: true,
          align: "right",
        },
      ],
      search: {},
      total: null,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  watch: {
    options: {
      handler() {
        // this.getDataFromApi()
        this.loadList(this.options);
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    companyId(n, o) {
      if (n !== o) {
        this.loadList();
      }
    },
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.company_id = this.company.id;

      this.$api
        .query("clusters", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    show(params) {
      this.providerId = params.provider_id;

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
      setTimeout(() => this.$emit("dismissed"), 300);
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.selectedItems.length) {
        this.$toast.error("En az bir TYA seçmelisiniz");
      }

      const formData = {
        cluster_ids: this.selectedItems.map((v) => v.id),
      };

      this.isLoading = true;

      this.$api
        .post(`company-providers/${this.providerId}/clusters`, formData)
        .then((response) => {
          const count = response.data.data.count;
          this.$toast.success(`${count} adet TYA'ya eklendi`);
          this.$emit("saved", response.data.data);
          this.$emit("created", response.data.data);
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
